.adyen-checkout__payment-method__image__wrapper {
  display: none;
}

.adyen-checkout__payment-method:last-child {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.adyen-checkout__payment-method--standalone
  .adyen-checkout__payment-method__header {
  cursor: pointer;
  padding: 16px 0 16px 48px;
}

.adyen-checkout__payment-method__details {
  padding: 0 0 0 48px;
}

.adyen-checkout__button--pay {
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e9e9e9;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: block;
  font-family: 'Anton', sans-serif;
  font-size: 1.125em;
  font-weight: 600;
  -webkit-letter-spacing: 0.9px;
  -moz-letter-spacing: 0.9px;
  -ms-letter-spacing: 0.9px;
  letter-spacing: 0.9px;
  margin: 0 auto;
  max-width: 100%;
  min-width: 12.5em;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  background-color: #000;
  border: 0;
  color: #fff;
  font-size: 1.125em;
  font-weight: 400;
  -webkit-letter-spacing: 0.9px;
  -moz-letter-spacing: 0.9px;
  -ms-letter-spacing: 0.9px;
  letter-spacing: 0.9px;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}

.adyen-checkout__button--pay:hover {
  background-color: #e84160;
}

.adyen-checkout__input {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 0;
  color: #666;
  display: block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1em;
  padding: 0.75em;
  width: 100%;
  height: 52px;
}
