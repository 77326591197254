/* only for genral global styling */
html {
  box-sizing: border-box;
}

body {
  line-height: 1.4;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* This is to dampen the FOUC */
.loading-screen {
  background-color: #fff;
  display: flex;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999999;
}

.spinner {
  height: 40px;
  margin: auto;
  position: relative;
  width: 40px;
}

@keyframes skBounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.bounce-one,
.bounce-two {
  background-color: #aaa;
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;

  animation: skBounce 2s infinite ease-in-out;
}

.bounce-two {
  animation-delay: -1s;
}

select::-ms-expand {
  display: none;
}

.util-brandname {
  text-transform: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px eggshell inset;
}

/* https://github.com/reactjs/react-modal/issues/191#issuecomment-302172285 */
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* https://github.com/reactjs/react-modal/issues/392#issuecomment-307464756 */
.ReactModal__Content {
  max-height: 100%;
  overflow-y: auto;
}
