.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.Modal {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-radius: 0;
  border: 0;
  left: 50%;
  max-height: 90%;
  max-width: 1170px;
  outline: none;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 9999999;
}

.Modal--one-column {
  max-width: 700px;
}
